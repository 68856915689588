import "./App.css";
import React from 'react';
import Header from './components/header/Header';
import Hero from './components/Hero/Hero';
import Slider from './components/Slider/Slider';
import Virtual from './components/Virtual/Virtual';
import Products from "./components/Products/Products";
import Testimonials from "./components/Testimonial/Testimonials";
import Footer from "./components/Footer/Footer"


const App = () => {
  return (
    <div>
      <Header/>
      <Hero/>
      <Slider/>
      <Virtual/>
      <Products/>
      <Testimonials/>
      <Footer/>
    </div>
  )
}

export default App

